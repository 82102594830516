<template>
  <Page
    has-actions
    :loading="loading"
    :subtitle="`${total} ${$tc('result', total <= 1 ? 1 : 2)}`"
  >
    <Card
      v-if="$can('read', 'international.countries')"
      content-class="c-table"
    >
      <b-table
        :data="items"
        :total="items.length"
        :per-page="pagination.perPage"
        :current-page="pagination.page"
        paginated
        hoverable
      >
        <b-table-column
          v-slot="props"
          field="iso"
          :label="this.$i18n.t('iso')"
          width="80"
          header-class="sticky-header"
          sortable
        >
          <a
            :id="props.row.iso"
            href="javascript:;"
            class="is-clickable"
            
            @click="
              openDrawer('editCountry', {
                list: props.row,
                add: false,
                filters: filters,
              })
            "
          >
            {{ props.row.iso }}
          </a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="name"
          :label="this.$i18n.tc('country', 1)"
          width="150"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.name ? props.row.name : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="original_name"
          :label="this.$i18n.t('original_name')"
          width="200"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.original_name ? props.row.original_name : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="continent"
          :label="this.$i18n.t('continent')"
          width="100"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.continent ? props.row.continent : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="code"
          :label="this.$i18n.tc('code',1)"
          width="100"
          header-class="sticky-header"
          sortable
        >
          <span>
            {{ props.row.code ? props.row.code : "-" }}
          </span>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="active"
          :label="$tc('active', 1)"
          header-class="sticky-header"
          sortable
          centered
          width="40"
        >      
          <b-icon
            :icon=" props.row.active ? 'check' : 'close'"
            :type="props.row.active ? 'is-success' : 'is-danger'"
            size="is-small"
          />
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="action"
          :label="$t('actions')"
          header-class="sticky-header"
          centered
          width="40"
        >
          <b-dropdown
            :key="props.row.iso"
            aria-role="list"
            position="is-bottom-left"
            append-to-body
          >
            <div slot="trigger">
              <b-icon
                icon="dots-vertical"
                size="is-small"
                class="is-clickable"
              />
            </div>

            <b-dropdown-item
              aria-role="listitem"
              @click="openCountryDrawer(props.row, false)"
            >
              <b-icon
                icon="eye"
                size="is-small"
              />
              {{ $t("view") }}
            </b-dropdown-item>

            <b-dropdown-item
              v-if="($can('delete', 'international.countries'))"
              aria-role="listitem"
              @click="deleteRestoreCountry(props.row)"
            >
              <b-icon
                :icon="
                  props.row.active ? 'delete' : 'refresh'
                "
                :type="
                  props.row.active
                    ? 'is-danger'
                    : 'is-success'
                "
                size="is-small"
              />
              {{
                $t(
                  props.row.active
                    ? "delete"
                    : "restore"
                )
              }}
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </b-table>
      <!-- <b-button
        size="is-small"
        type="is-primary"
        outlined
        class="mt-2 ml-2"
        @click="openCountryDrawer([], true)"
      >
        {{ $t("add_country") }}
      </b-button>   -->
      <Pagination
        :per-page="pagination.perPage"
        :per-pages="rowsPerPage"
        :total="items.length"
        :current-page="pagination.page"
        @update-per-page="val => (pagination.perPage = val)"
        @update-current-page="val => (pagination.page = val)"
      /> 
    </Card>
  </Page>
</template>

<script>
export default { 
  data() {
    return {
      rowsPerPage: [50, 100, 200],
      pagination: { perPage: 50, page: 1 },
      loading: false,
      total:0,
	    items: [],
      filters: [],
    };
  },
  created() {
    this.updateTitle(this.$i18n.tc("country", 2));
	  this.getItems();
  },  
  methods: {
	  getItems() {
      if (!this.$can("read", "international.countries")) return;
      this.loading = true;
      this.$axios
        .get(`/countries`)
          .then(res  => {
            this.items = res.data.list;
            this.filters = res.data.filters;
            this.total = res.data.pagination.total;
          })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
    },
	  openCountryDrawer(list, add) {
      let options = {
		  list: list,
		  add: add,
      filters: this.filters,
        
      };
      this.openDrawer("editCountry", options);
    },
    deleteRestoreCountry(country) {
      if (country.active) {
        this.$axios
        .delete(`/countries/${country.iso}`)
          .then(res  => {
            country.active = false;
            this.$root.notify(this.$t("successfully_deleted"), "is-success");
          })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
      }
      else {
        this.$axios
        .patch(`/countries/${country.iso}/restore`)
          .then(res  => {
            country.active = true;
            this.$root.notify(this.$t("successfully_restored"), "is-success");
          })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading = false));
      }
    }
  }
};
</script>